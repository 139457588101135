import { EditorSDK } from '@wix/platform-editor-sdk';

import { getTranslationFunction } from '../../../../i18n';
import { addPage, remove } from '../../../wrappers/pages';
import { getById } from '../../../wrappers/components';
import { getMembersAreaPageRef } from './members-area-page';
import { toMonitored } from '../../../../utils/monitoring';

export const createBlankPrivatePage = async (editorSDK: EditorSDK) => {
  const t = await toMonitored('private-api.v2.createPrivateMemberPage.getTranslationFunction', () =>
    getTranslationFunction(editorSDK),
  );

  const pageTitle = t('Pages_Private_Member_Page_Title');
  const pageRef = await toMonitored('private-api.v2.createPrivateMemberPage.addPage', () =>
    addPage({
      editorSDK,
      pageTitle,
      pageSecurity: { requireLogin: true },
      shouldAddMenuItem: false,
    }),
  );

  return { pageTitle, pageRef };
};

export const removePage = async (editorSDK: EditorSDK, { pageId }: { pageId: string }) => {
  const pageRef = await getById({ editorSDK, id: pageId });
  if (!pageRef) {
    throw new Error('Page not found');
  }

  const membersAreaPageRef = await getMembersAreaPageRef(editorSDK).catch(() => undefined);

  return remove({
    editorSDK,
    pageRef,
    pageToNavigateAfterRemove: membersAreaPageRef,
    shouldShowEditorRemovePanel: false,
  });
};
