import { IntegrationApplication, WidgetId } from '@wix/members-area-app-definitions';
import { getAppDefinitions } from '@wix/members-area-app-definitions/dist/esm/getAppDefinition';
import { createInstance } from 'i18next';
import { EditorReadyFn, EditorSDK } from '@wix/platform-editor-sdk';

import { editorReady } from '../platform-app/ma-on-msb/editor-ready';
import { getMembersAreaRouters } from './routers';
import { installApps } from '../platform-app/ma-on-msb/integration';
import { MY_ACCOUNT_APP_DEF_ID, PROFILE_PAGE_BOB_APP_DEF_ID, PROFILE_WIDGET_APP } from '../constants';
import { setIsMigration } from './applicationState';
import { getStyleParams, getTpaData, parseStyleParamsForSetter, parseTpaDataParamsForSetter } from '../wrappers/tpa';
import { getComponentsDataByType } from '../wrappers/components';

const PROFILE_CARD_APP = { appDefinitionId: PROFILE_WIDGET_APP.appDefinitionId, widgetId: WidgetId.ProfileCard };
const MY_ACCOUNT_APP = { appDefinitionId: MY_ACCOUNT_APP_DEF_ID, widgetId: WidgetId.MyAccount };

const REF_COMPONENT_TYPE = 'wysiwyg.viewer.components.RefComponent';

const STRETCHED_ITEM_LAYOUT = {
  justifySelf: 'stretch',
  alignSelf: 'stretch',
  margins: {
    left: { type: 'px', value: 0 },
    right: { type: 'px', value: 0 },
    top: { type: 'px', value: 0 },
    bottom: { type: 'px', value: 0 },
  },
};

const STRETCHED_COMPONENT_LAYOUT = {
  width: { type: 'auto' },
  maxWidth: { type: 'percentage', value: 100 },
};

const getWidgetRef = async (editorSDK: EditorSDK, widgetId: string, componentType: string) => {
  const componentsRefs = await getComponentsDataByType(editorSDK, componentType);
  const widget = componentsRefs.filter(
    (refComp) => (refComp as { data: { widgetId: string } }).data.widgetId === widgetId,
  )[0];
  return widget?.componentRef;
};

const getProfilePageBobWidgetRef = async (editorSDK: EditorSDK) => {
  const widgetRef = await getWidgetRef(editorSDK, WidgetId.ProfilePageBob, REF_COMPONENT_TYPE);
  return widgetRef;
};

const getProfileCardPublicApi = (editorSDK: EditorSDK) =>
  editorSDK.application.getPublicAPI('', {
    appDefinitionId: PROFILE_WIDGET_APP.appDefinitionId,
  }) as Promise<any>;

const getAlreadyInstalledApplications = async (editorSDK: EditorSDK) => {
  // TO DO: handle unexpected states of routers
  const routers = await getMembersAreaRouters(editorSDK);
  // @ts-expect-error - "patterns" type is invalid
  const privatePages = Object.values(routers.privateRouter.config.patterns).map((p) => p.appData);
  // @ts-expect-error - "patterns" type is invalid
  const publicPages = Object.values(routers.publicRouter.config.patterns || {}).map((p) => p.appData);

  const allPages = [...privatePages, ...publicPages];
  const appDefinitionsToInstall = allPages.map(({ appDefinitionId, appPageId }) => ({
    appDefinitionId,
    pageId: appPageId,
  }));

  return getAppDefinitions({
    applications: appDefinitionsToInstall.filter((app) => app.appDefinitionId !== MY_ACCOUNT_APP_DEF_ID),
    editorSDK,
    i18next: createInstance(),
  });
};

// TO DO: handle deleted profile card
// TO DO: copy margins from masterPage profile card
// TO DO: handle non full width profile cards
const applyPageLayouts = async (editorSDK: EditorSDK) => {
  const memberPageRef = await getProfilePageBobWidgetRef(editorSDK);
  const newProfileCardRef = await getWidgetRefInGivenMA(editorSDK, PROFILE_CARD_APP, 'v2');
  const profileCardPublicApi = await getProfileCardPublicApi(editorSDK);
  await profileCardPublicApi?.setHorizontalLayout(newProfileCardRef);

  const memberPageLayout = await editorSDK.document.responsiveLayout.get('', { componentRef: memberPageRef });
  Object.assign(memberPageLayout.itemLayouts[0], STRETCHED_ITEM_LAYOUT);
  Object.assign(memberPageLayout.componentLayouts[0], STRETCHED_COMPONENT_LAYOUT);

  await editorSDK.document.responsiveLayout.update('', {
    componentRef: memberPageRef,
    responsiveLayout: memberPageLayout,
  });
};

const installNewMembersArea: EditorReadyFn = async (editorSDK, _, options) => {
  await editorReady(editorSDK, _, options);
  await applyPageLayouts(editorSDK);
};

async function installVerticalsApplications(editorSDK: EditorSDK, appDefinitions: IntegrationApplication[]) {
  await installApps(editorSDK, appDefinitions);
}

async function getMaV2Page(editorSDK: EditorSDK) {
  const allPages = await editorSDK.pages.data.getAll('');
  const v2Page = allPages.find((page) => page.appDefinitionId === PROFILE_PAGE_BOB_APP_DEF_ID);

  if (!v2Page) {
    throw new Error('MA V2 page not found when expected');
  }

  return v2Page;
}

async function getWidgetRefInGivenMA(
  editorSDK: EditorSDK,
  { widgetId, appDefinitionId }: { widgetId: string; appDefinitionId: string },
  maVersion: 'v1' | 'v2',
) {
  const v2Page = await getMaV2Page(editorSDK);
  const appData = await editorSDK.tpa.app.getDataByAppDefId('', appDefinitionId);
  const appsWidgets = await editorSDK.tpa.app.getAllCompsByApplicationId('', appData.applicationId);
  const widgetComps = appsWidgets.filter((widget) => widget.widgetId === widgetId);
  const widget = widgetComps.find((w) => (maVersion === 'v2' ? w.pageId === v2Page.id : w.pageId !== v2Page.id));

  if (!widget) {
    throw new Error(`Failed to find widget ${widgetId} in MA ${maVersion}`);
  }

  return editorSDK.components.getById('', { id: widget.id });
}

async function copyApplicationsSettings(editorSDK: EditorSDK, appDefinitions: IntegrationApplication[]) {
  const [oldMyAccountRef, newMyAccountRef, oldProfileCardRef, newProfileCardRef, oldWidgetsRefs, newWidgetsRefs] =
    await Promise.all([
      getWidgetRefInGivenMA(editorSDK, MY_ACCOUNT_APP, 'v1'),
      getWidgetRefInGivenMA(editorSDK, MY_ACCOUNT_APP, 'v2'),
      getWidgetRefInGivenMA(editorSDK, PROFILE_CARD_APP, 'v1'),
      getWidgetRefInGivenMA(editorSDK, PROFILE_CARD_APP, 'v2'),
      Promise.all(appDefinitions.map((app) => getWidgetRefInGivenMA(editorSDK, app, 'v1'))),
      Promise.all(appDefinitions.map((app) => getWidgetRefInGivenMA(editorSDK, app, 'v2'))),
    ]);

  oldWidgetsRefs.push(...[oldMyAccountRef, oldProfileCardRef].filter(Boolean));
  newWidgetsRefs.push(...[newMyAccountRef, newProfileCardRef].filter(Boolean));

  const [oldWidgetsStyles, oldWidgetsDatas] = await Promise.all([
    Promise.all(oldWidgetsRefs.map((ref) => getStyleParams(editorSDK, ref))),
    Promise.all(oldWidgetsRefs.map((ref) => getTpaData(editorSDK, ref))),
  ]);

  // We have no permissions to set other TPAs styleParams which is an issue
  // This works unexpectedly though - not secured like tpa.data permissions
  try {
    await Promise.all(
      newWidgetsRefs.map(async (compRef, index) => {
        const mappedParams = parseStyleParamsForSetter(oldWidgetsStyles[index]);
        await editorSDK.tpa.setStyleParams('', { compRef, styleParams: mappedParams });
      }),
    );
  } catch (e) {
    console.log('Failed to copy some widgets style params while migrating Members Area');
  }

  // We have no permissions to set other TPAs datas which is an issue
  // This code would do it, now it will print the errors
  try {
    await Promise.all(
      newWidgetsRefs.map(async (compRef, index) => {
        const componentDataParams = oldWidgetsDatas[index].COMPONENT || {};
        const mappedDatas = parseTpaDataParamsForSetter(componentDataParams, compRef);
        await Promise.all(mappedDatas.map((data) => editorSDK.tpa.data.set('', data)));
      }),
    );
  } catch (e) {
    console.log('Failed to copy some widgets data params while migrating Members Area');
  }
}

export const migrateToBlocksMembersArea: EditorReadyFn = async (editorSDK, _, options) => {
  setIsMigration(true);
  console.log('Members Area: Starting migration to V2');

  const alreadyInstalledApplications = await getAlreadyInstalledApplications(editorSDK);
  await installNewMembersArea(editorSDK, _, options);
  await installVerticalsApplications(editorSDK, alreadyInstalledApplications);
  await copyApplicationsSettings(editorSDK, alreadyInstalledApplications);

  setIsMigration(false);
  console.log('Members Area: Migration to V2 finished');
};
