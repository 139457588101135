import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { WIX_STORES } from '@wix/app-definition-ids';

import { APP_TOKEN, MENU_IDS } from '../../../constants';
import { createLoginIconsMenu, createLoginMenu } from '../../../wrappers/menus';
import { addLoginButton } from './login-bar';

export const addLoginMenus = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const isStoresInstallation = options.origin.info?.appDefinitionId === WIX_STORES;
  const headerRef = await editorSDK.siteSegments.getHeader(APP_TOKEN);

  await Promise.all([createLoginMenu(editorSDK), createLoginIconsMenu(editorSDK)]);
  await addLoginButton(editorSDK, headerRef, isStoresInstallation);
};

export const addMembersSubMenu = async (editorSDK: EditorSDK) => {
  await editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Member Menu', items: [] },
    customId: MENU_IDS.SUB_MENU_ID,
  });
};
