import { EditorSDK, EventType } from '@wix/platform-editor-sdk';

import { HistoryLabel } from '../../../wrappers/history';
import { uninstallMembersArea } from '../services/uninstall';

const onUndo = async (editorSDK: EditorSDK) => {
  await editorSDK.addEventListener(EventType.undo, async ({ detail }) => {
    if (detail.label === HistoryLabel.InstallV2) {
      await uninstallMembersArea(editorSDK);
    }
  });
};

export const addEventsListeners = async (editorSDK: EditorSDK) => {
  await onUndo(editorSDK);
};
