import { MEMBERS_AREA } from '@wix/app-definition-ids';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { toMonitored } from '../../../utils/monitoring';
import { fixGlobalControllerIfMissing } from './services/controllers';
import { verifyMembersAreaInstallation } from './services/installation-verification';
import { getIsMembersAreaV2 } from '../../services/context';
import { uninstallMembersArea } from './services/uninstall';

const monitoredUninstallMembersArea = async (editorSDK: EditorSDK) => {
  try {
    await toMonitored('v2.editor.appInstalled.uninstallMembersArea', () => uninstallMembersArea(editorSDK));
  } catch (e) {}
};

const monitoredFixGlobalControllerIfMissing = async (editorSDK: EditorSDK) => {
  try {
    await toMonitored('v2.editor.appInstalled.fixGlobalController', () => fixGlobalControllerIfMissing(editorSDK));
  } catch (e) {}
};

const verifyInstallation = async (editorSDK: EditorSDK) => {
  try {
    await monitoredFixGlobalControllerIfMissing(editorSDK);

    await verifyMembersAreaInstallation(editorSDK);
  } catch (e) {
    await monitoredUninstallMembersArea(editorSDK);
    throw e;
  }
};

export const handleAppInstalled = async (editorSDK: EditorSDK, appDefinitionId: string) => {
  if (appDefinitionId === MEMBERS_AREA && (await getIsMembersAreaV2(editorSDK))) {
    return toMonitored('v2.editor.appInstalled', () => verifyInstallation(editorSDK));
  }
};
