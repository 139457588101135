import { EditorSDK } from '@wix/platform-editor-sdk';
import { monitoredTransactionFactory, toMonitored } from '../../../utils/monitoring';

import { MENU_IDS } from '../../constants';
import { getPageData, setPagesState } from '../../wrappers/pages';
import { addMenuItem } from '../../wrappers/menus';
import { getPublicApplications } from './services/members-area-page';
import { navigateToMembersAreaPage } from './services/navigation';
import { MembersAreaPrivateApi } from '../types';
import { uninstallMembersArea } from './services/uninstall';
import { createBlankPrivatePage, removePage } from './services/pages';

export const createPrivateAPI = (editorSDK: EditorSDK): MembersAreaPrivateApi => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);

  return {
    hasSocialPages: () => {
      const action = async () => {
        await navigateToMembersAreaPage(editorSDK);
        const installedPublicApps = await getPublicApplications(editorSDK);
        return installedPublicApps.length > 0;
      };

      return monitoredTransaction('private-api.v2.hasSocialPages', action);
    },
    uninstall: () => {
      return monitoredTransaction('v2.uninstall', () => uninstallMembersArea(editorSDK));
    },
    removePage: (pageData) => {
      const action = () => removePage(editorSDK, { pageId: pageData.id });

      return monitoredTransaction('private-api.v2.removePage', action);
    },
    createPrivateMemberPage: () => {
      const action = async () => {
        const { pageTitle, pageRef } = await toMonitored(
          'private-api.v2.createPrivateMemberPage.createBlankPrivatePage',
          () => createBlankPrivatePage(editorSDK),
        );

        const { id: pageId } = await toMonitored('private-api.v2.createPrivateMemberPage.getPageData', () =>
          getPageData({ editorSDK, pageRef }),
        );

        await toMonitored('private-api.v2.createPrivateMemberPage.addMenuItem', () =>
          addMenuItem({
            editorSDK,
            menuId: MENU_IDS.LOGIN_MENU_ID,
            menuItem: {
              label: pageTitle,
              link: {
                pageId: pageId!,
                target: '_self',
                type: 'PageLink',
              },
            },
          }),
        );

        await toMonitored('private-api.v2.createPrivateMemberPage.setPagesState', () =>
          setPagesState({ editorSDK, state: { custom: [pageRef] } }),
        );

        return { pageRef, pageTitle };
      };

      return monitoredTransaction('private-api.v2.createPrivateMemberPage', action);
    },
    /** @deprecated */
    updatePageData: ({ pageRef, pageData }) => {
      throw new Error('[MAV2] PrivateAPI.updatePageData is not implemented');
    },
    /** @deprecated */
    getAllRouters: () => {
      throw new Error('[MAV2] PrivateAPI.getAllRouters is not implemented');
    },
    /** @deprecated */
    getRouterDataByPage: (pageRef) => {
      throw new Error('[MAV2] PrivateAPI.getRouterDataByPage is not implemented');
    },
    /** @deprecated */
    updatePageDataInRouter: (pageRef, dataFieldToUpdate, updatedData) => {
      throw new Error('[MAV2] PrivateAPI.updatePageDataInRouter is not implemented');
    },
    /** @deprecated */
    saveInnerRoute: (newInnerRoute, pageRef, pageRouterData) => {
      throw new Error('[MAV2] PrivateAPI.saveInnerRoute is not implemented');
    },
    /** @deprecated */
    savePasswordPermissions: (pageRef, privacy, plainPassword) => {
      throw new Error('[MAV2] PrivateAPI.savePasswordPermissions is not implemented');
    },
    /** @deprecated */
    renamePage: (pageName, pageRef, pageRouterData) => {
      throw new Error('[MAV2] PrivateAPI.renamePage is not implemented');
    },
  };
};
