import { AppManifest, BaseActionConfig } from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';

import { AppManagerAction } from '../../types';

type CustomAppActions = NonNullable<AppManifest['appDescriptor']>['customActions'];

const getCustomAppActions = async (t: TranslationFunction): Promise<CustomAppActions> => [
  {
    actionId: AppManagerAction.OpenMembersAreaPagesPanel,
    type: 'editorActions',
    icon: 'appManager_pagesAction',
    title: t('Apps_Manager_Manage_Member_Pages'),
  },
  {
    actionId: AppManagerAction.OpenMembersAddPanel,
    type: 'editorActions',
    icon: 'appManager_addElementsAction',
    title: t('Apps_Manager_Open_Members_Add_Panel'),
  },
  {
    actionId: AppManagerAction.OpenMembersAccountBmDashboard,
    type: 'dashboard' as BaseActionConfig['type'],
    icon: 'appManager_settingsAction',
    title: t('Apps_Manager_Customize_Members_Profiles'),
  },
  {
    actionId: AppManagerAction.OpenBadgesDashboard,
    type: 'dashboard',
    icon: 'appManager_settingsAction',
    title: t('Apps_Manager_Open_Badges_Page'),
  },
  {
    actionId: AppManagerAction.OpenSiteMembersDashboard,
    type: 'dashboard',
    icon: 'appManager_settingsAction',
    title: t('Apps_Manager_Engage_With_Your_Members'),
  },
];

export const getAppDescriptor = async (t: TranslationFunction): Promise<AppManifest['appDescriptor']> => {
  return {
    mainActions: [],
    defaultActions: { learnMoreKB: 'b74bcbba-91c8-4951-a3de-a1dc698f6356' },
    customActions: await getCustomAppActions(t),
  };
};
