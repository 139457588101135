import { EditorSDK } from '@wix/platform-editor-sdk';
import { APP_TOKEN } from '../constants';

export enum HistoryLabel {
  InstallV2 = 'umbrellaMembersAreaV2Install',
  UninstallV2 = 'Deleting Members Area',
}

const historyTimeouts: Record<string, NodeJS.Timeout> = {};

async function add(editorSDK: EditorSDK, label: HistoryLabel | string) {
  await editorSDK.history.add(APP_TOKEN, { label });
}

async function addWithDelay(editorSDK: EditorSDK, label: HistoryLabel | string, delay = 0) {
  if (historyTimeouts[label]) {
    clearTimeout(historyTimeouts[label]);
  }

  historyTimeouts[label] = setTimeout(() => add(editorSDK, label), delay);
}

export { add, addWithDelay };
