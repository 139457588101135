import { EditorReadyFn, EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';

import { APP_TOKEN } from '../../constants';
import { log, monitoredTransactionFactory, toMonitored } from '../../../utils/monitoring';
import { startSequentialPromises, stopSequentialPromises } from '../../enforceSequentiality';
import { registerToComponentAddedToStageEvent } from '../../wrappers/components';
import { registerAlwaysAvailableApps } from './integration';
import { fixGlobalControllerIfMissing, removeGlobalControllerIfExists } from './services/controllers';
import { getInstallationSuccessState } from './services/installation-verification';
import { getIsMigration } from '../../services/applicationState';
import * as history from '../../wrappers/history';
import { addEventsListeners } from './event-listener';
import { isApplicationInstalled } from '../../wrappers/application';
import { installMembersArea } from './editor-ready-utils/installation';
import { migrateMembersArea } from './editor-ready-utils/migration';

import { shouldEnableSignUpPrivacyNoteType } from '../../../utils/experiments';
import { isProfilePageBobValid } from './services/members-area-page';

const setSignUpPrivacyNoteType = async (editorSDK: EditorSDK) => {
  try {
    if (await shouldEnableSignUpPrivacyNoteType()) {
      await editorSDK.siteMembers.setPrivacyNoteType(APP_TOKEN, 'NOTE');
    }
  } catch {
    log('Failed to set privacyNoteType');
  }
};

const shouldInstallMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  if (!options.firstInstall) {
    return false;
  }

  return !(await isApplicationInstalled(editorSDK, MEMBERS_AREA_V2));
};

const monitoredFixGlobalControllerIfMissing = async (editorSDK: EditorSDK) => {
  try {
    await toMonitored('v2.editorReady.fixGlobalController', () => fixGlobalControllerIfMissing(editorSDK));
  } catch (e) {}
};

const validateAndFix = async (editorSDK: EditorSDK) => {
  if (await isProfilePageBobValid(editorSDK)) {
    await monitoredFixGlobalControllerIfMissing(editorSDK);
  }

  const installationState = await getInstallationSuccessState(editorSDK);

  if (!installationState.success) {
    log(`Members Area v2.editorReady validation: ${installationState.error}`);
  }
};

export const editorReady: EditorReadyFn = async (editorSDK, _, options) => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  const isMigration = getIsMigration();

  try {
    const editorReadyAction = async () => {
      if (await shouldInstallMembersArea(editorSDK, options)) {
        await removeGlobalControllerIfExists(editorSDK);

        await monitoredTransaction('v2.install', () => installMembersArea(editorSDK, options));
        await setSignUpPrivacyNoteType(editorSDK);
        await history.add(editorSDK, history.HistoryLabel.InstallV2);
      } else if (isMigration) {
        await monitoredTransaction('v2.migration', () => migrateMembersArea(editorSDK, options));
      }

      await registerToComponentAddedToStageEvent(editorSDK);
      await registerAlwaysAvailableApps(editorSDK);
      await addEventsListeners(editorSDK);

      if (!options.firstInstall) {
        await validateAndFix(editorSDK);
      }

      startSequentialPromises();
    };

    await toMonitored('v2.editorReady', editorReadyAction);
  } catch (error: any) {
    console.error('Members Area V2 editorReady failed');
    console.error(error);
    stopSequentialPromises(error);

    throw error;
  }
};
