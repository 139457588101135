import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

import { addGlobalController, removeGlobalControllerIfExists } from '../services/controllers';
import { addMembersAreaPage } from '../services/members-area-page';
import { addMyAccountMenuItemToMembersAreaMenus } from '../services/menu-items';
import { verifyMembersAreaInstallation } from '../services/installation-verification';
import { uninstallMembersArea } from '../services/uninstall';

export const migrateMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  try {
    await removeGlobalControllerIfExists(editorSDK);
    await addGlobalController(editorSDK);
    await addMembersAreaPage(editorSDK);
    await addMyAccountMenuItemToMembersAreaMenus(editorSDK, options);

    await verifyMembersAreaInstallation(editorSDK);
  } catch (e) {
    // TO DO: Think on how to handle failed migration
    await uninstallMembersArea(editorSDK);
    throw e;
  }
};
