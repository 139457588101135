import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-app-definitions';

import { APP_TOKEN, PROFILE_PAGE_BOB_APP_DEF_ID, SANTA_MEMBERS_APP_ID } from '../../../constants';
import {
  IsSectionInstalledProps,
  MembersAreaPagePublicApi,
  UpdateRouteConfigurationRequest,
  WidgetPluginPointer,
} from '../../types';
import { getIsClassicEditor } from '../../../services/applicationState';
import { shouldUseGlobalControllerRoutes } from '../../../../utils/experiments';
import { getRoutesFromGlobalController } from './controllers';
import { log } from '../../../../utils/monitoring';

export const MA_PUBLIC_API_NOT_FOUND_ERROR = '[MAV2] Members Area Page PublicAPI is not found';

export const getMembersAreaPagePublicAPI = async (editorSDK: EditorSDK) => {
  const membersAreaPagePublicApi = await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
  });

  if (membersAreaPagePublicApi) {
    return membersAreaPagePublicApi as MembersAreaPagePublicApi;
  }

  throw new Error(MA_PUBLIC_API_NOT_FOUND_ERROR);
};

export const getMembersAreaPageRef = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getMembersAreaPageRef();
};

export const getInstalledApplications = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getInstalledWidgetPlugins();
};

export const getPublicApplications = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getPublicApplications();
};

export const getRoutes = async (editorSDK: EditorSDK) => {
  const useGlobalRoutes = await shouldUseGlobalControllerRoutes();

  // When clearing, move logic to controllers.ts
  if (useGlobalRoutes) {
    try {
      return getRoutesFromGlobalController(editorSDK);
    } catch (e) {
      log('Failed to fetch routes from global controller');
      throw e;
    }
  }

  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getRoutes();
};

export const isAppSectionInstalled = async (editorSDK: EditorSDK, { widgetId }: IsSectionInstalledProps) => {
  const routes = await getRoutes(editorSDK);
  return routes.some((route) => route.widgetId === widgetId);
};

export const setRouteData = async (editorSDK: EditorSDK, payload: UpdateRouteConfigurationRequest) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.updateRoute(payload);
};

export const addApplicationsToSlots = async (
  editorSDK: EditorSDK,
  applications: IntegrationApplication[],
  shouldNavigate: boolean = false,
) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.addWidgetsPlugins(applications, shouldNavigate);
};

export const removeApplicationsFromSlots = async (editorSDK: EditorSDK, applications: WidgetPluginPointer[]) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  const widgetsIds = applications.map(({ widgetId }) => widgetId);

  return membersAreaPageAPI.removeWidgets(widgetsIds);
};

export const showProfileCard = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.showProfileCard();
};

export const hideProfileCard = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.hideProfileCard();
};

export const uninstallProfilePageBoB = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.uninstall();
};

export const refreshMemberPage = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.refreshApp();
};

export const populateGlobalControllerWithRoutes = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.populateGlobalControllerWithRoutes();
};

export const isProfilePageBobValid = async (editorSDK: EditorSDK) => {
  try {
    const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
    return await membersAreaPageAPI.isProfilePageBobValid();
  } catch (e) {
    return false;
  }
};

export const addMembersAreaPage = async (editorSDK: EditorSDK) => {
  const avoidNavigation = getIsClassicEditor();
  return editorSDK.document.application.add(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
    managingAppDefId: SANTA_MEMBERS_APP_ID,
    shouldNavigate: !avoidNavigation,
    isSilent: true,
  });
};
