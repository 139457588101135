import { APP_TOKEN } from '../constants';
import { ComponentRef, EditorSDK, StyleParam, StyleParams, TPAPublicDataScope } from '@wix/platform-editor-sdk';

const STYLE_PARAMS_TYPES_MAP = {
  booleans: 'boolean',
  numbers: 'number',
  fonts: 'font',
  colors: 'color',
  manuals: 'manual',
};

function addApplication(editorSDK: EditorSDK, widgetDef: Parameters<EditorSDK['tpa']['add']['application']>[1]) {
  return editorSDK.tpa.add.application(APP_TOKEN, widgetDef);
}

function addComponent(editorSDK: EditorSDK, options: Parameters<EditorSDK['tpa']['add']['component']>[1]) {
  return editorSDK.tpa.add.component(APP_TOKEN, options);
}

function isApplicationInstalled({ editorSDK, appDefinitionId }: { editorSDK: EditorSDK; appDefinitionId: string }) {
  return editorSDK.tpa.isApplicationInstalled(APP_TOKEN, { appDefinitionId });
}

function isAppSectionInstalled({
  editorSDK,
  appDefinitionId,
  sectionId,
}: {
  editorSDK: EditorSDK;
  appDefinitionId: string;
  sectionId: string;
}) {
  return editorSDK.tpa.isAppSectionInstalled(APP_TOKEN, { appDefinitionId, sectionId });
}

function getDataByAppDefId({ editorSDK, appDefinitionId }: { editorSDK: EditorSDK; appDefinitionId: string }) {
  return editorSDK.tpa.app.getDataByAppDefId(APP_TOKEN, appDefinitionId);
}

function getAllCompsByApplicationId({
  editorSDK,
  applicationId,
}: {
  editorSDK: EditorSDK;
  applicationId: Parameters<EditorSDK['tpa']['app']['getAllCompsByApplicationId']>[1];
}) {
  return editorSDK.tpa.app.getAllCompsByApplicationId(APP_TOKEN, applicationId);
}

async function getAppDefIdByTpaAppId({
  editorSDK,
  tpaAppId,
}: {
  editorSDK: EditorSDK;
  tpaAppId: Parameters<EditorSDK['tpa']['app']['getAllCompsByApplicationId']>[1];
}) {
  const components = await editorSDK.tpa.app.getAllCompsByApplicationId('', tpaAppId);
  return components?.[0]?.appDefinitionId;
}

function getStyleParams(editorSDK: EditorSDK, compRef: ComponentRef) {
  return editorSDK.tpa.getStyleParams(APP_TOKEN, { compRef });
}

function getTpaData(editorSDK: EditorSDK, compRef: ComponentRef) {
  return editorSDK.tpa.data.getAll(APP_TOKEN, { compRef });
}

function parseStyleParamForSetter(styleParams: StyleParams, styleType: string, styleParam: string) {
  if (styleType === 'colors') {
    const paramValue = styleParams[styleType][styleParam];
    return { color: { value: paramValue.value, name: paramValue.themeName } };
  }

  return styleParams[styleType][styleParam];
}

function parseStyleParamsForSetter(styleParams: StyleParams): StyleParam[] {
  const compatibleStyles: StyleParam[] = [];

  for (const styleType of Object.keys(styleParams)) {
    const setterType = STYLE_PARAMS_TYPES_MAP[styleType];

    if (typeof setterType === 'undefined') {
      continue;
    }

    for (const styleParam of Object.keys(styleParams[styleType])) {
      const param = { value: parseStyleParamForSetter(styleParams, styleType, styleParam) };
      compatibleStyles.push({ key: styleParam, type: setterType, param });
    }
  }

  return compatibleStyles;
}

function parseTpaDataParamsForSetter(dataParams, compRef: ComponentRef) {
  return Object.keys(dataParams).map((key) => ({
    compRef,
    key,
    value: dataParams[key]!,
    scope: 'COMPONENT' as TPAPublicDataScope,
  }));
}

export {
  addApplication,
  addComponent,
  isApplicationInstalled,
  isAppSectionInstalled,
  getDataByAppDefId,
  getAllCompsByApplicationId,
  getAppDefIdByTpaAppId,
  getStyleParams,
  getTpaData,
  parseStyleParamsForSetter,
  parseTpaDataParamsForSetter,
};
